<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start"><ion-menu-button></ion-menu-button></ion-buttons>
    <ion-title>{{'My Records' | translate}}</ion-title>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-segment #ionSegment (ionChange)="segmentChanged($event)" [value]="selectedSegment">
      <ion-segment-button value="dictation">{{'Dictation' | translate }}</ion-segment-button>
      <ion-segment-button value="vocabulary">{{'Vocabulary' | translate }}</ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-sm="12" size-md="12" size-lg="6">
        <member-score-list [allTimesScore]="allTimesScore" [scores]="latestScore" [loading]="loadingAllTimesAndLast6Score"></member-score-list>
        <member-practice-history-list *ngIf="selectedSegment === 'dictation'" [histories]="practiceHistories" [loading]="loadingPracticeHistories"></member-practice-history-list>
        <app-dictation-list *ngIf="selectedSegment === 'vocabulary'"
                            [dictations]="createdVocabExercise" [loading]="loadingAllDictations"
                            [showCreateButton]="false" title="Vocabulary Exercise">
        </app-dictation-list>
      </ion-col>
      <ion-col size="12" size-sm="12" size-md="12" size-lg="6" *ngIf="selectedSegment === 'dictation'">
        <app-dictation-list [dictations]="createdDictations" [loading]="loadingAllDictations"
                            [showCreateButton]="true" title="My Dictation Created">
        </app-dictation-list>
      </ion-col>
      <ion-col size="12" size-sm="12" size-md="12" size-lg="6" *ngIf="selectedSegment === 'vocabulary'">
        <app-vocab-history-list [vocabs]="answeredBeforeVocabs" [loading]="loadingVocabHistory"
                                title="Answered Before"
                                [icon]="faTimesCircle"
                                infoText="Not include learnt"
                                [showReview]="answeredBeforeVocabs?.size > 0"
                                (click)="onVocabHistoryList($event)">
        </app-vocab-history-list>

        <app-vocab-history-list [vocabs]="learntVocabs" [loading]="loadingVocabHistory"
                                title="Learnt"
                                [icon]="faCheckCircle">
        </app-vocab-history-list>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-footer></app-footer>
</ion-content>
